<template>
  <component
    :is="component"
    :href="href || null"
    class="ttitle relative"
    :class="[colorClasses]"
  >
    <div
      class="flex flex-col items-left justify-center absolute inset-0 p-4 sm:p-6 md:p-8 lg:6 2xl:8 gap-4 md:gap-6"
    >
      <Icon
        v-if="icon"
        :name="icon"
        class="text-3xl"
        :style="responsiveFontClamp('icon')"
      />
      <h3
        v-if="title"
        class="leading-none"
        :style="responsiveFontClamp('heading')"
        v-html="$md.render(title)"
      ></h3>
      <div
        v-if="text"
        class="leading-none"
        :style="responsiveFontClamp('subheading')"
        v-html="$md.render(text)"
      ></div>
      <A11yButton
        v-if="cta"
        class="hidden sm:inline-flex whitespace-no-wrap text-base px-4"
        variant="primary"
        ><span class="truncate">{{ cta }}</span>
        <Icon name="IconChevronRight" class="ml-auto"
      /></A11yButton>
    </div>
  </component>
</template>

<script>
import useA11y from '~/composables/useA11y'
import useTheme from '~/composables/theme/useTheme'

export default {
  name: 'TafeTile',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
    cta: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { a11yTextColor, fontClamp } = useA11y()
    const { isInternational } = useTheme()
    return { a11yTextColor, fontClamp, isInternational }
  },
  data: () => ({
    clampSizes: {
      icon: {
        sm: ['3xl', '6xl', 'xxs', 'md'],
        lg: ['4xl', '6xl', 'md', '2xl']
      },
      heading: {
        sm: ['lg', '3xl', 'xxs', 'md'],
        lg: ['2xl', '4xl', 'md', '2xl']
      },
      subheading: {
        sm: ['base', '2xl', 'xxs', 'md'],
        lg: ['xl', '2xl', 'md', '2xl']
      }
    }
  }),
  computed: {
    component() {
      return this.cta ? 'a' : 'div'
    },
    colorClasses() {
      //TODO: refectory a11yTextColor function to match new base color and lightness for new color system
      if (this.isInternational) {
        return this.color
      }
      return this.a11yTextColor(this.color)
    },
    fontSize(min, max) {
      return this.fontClamp(min, max)
    }
  },
  methods: {
    responsiveFontClamp(el) {
      if (this.$viewport.isGreaterOrEquals('lg')) {
        return this.fontClamp(...this.clampSizes[el].lg)
      } else {
        return this.fontClamp(...this.clampSizes[el].sm)
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.ttitle:before {
  content: '';
  display: block;
  height: 0;
  padding-bottom: 100%;
}
</style>
